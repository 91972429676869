<template>
  <v-row>
    <v-col cols="12">
      <v-subheader v-text="$t('form.tab.ucfe')" />
      <ucfe-form v-model="obUcfe" :rut="obFirm.doc_id" />
    </v-col>

    <v-col cols="12">
      <v-divider />
      <v-subheader v-text="$t('form.tab.dgi')" />
      <firm-dgi-form v-model="obFirm" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Company, FirmData, UcfeData } from "@planetadeleste/vue-mc-gw";
import { Vue, Component, VModel } from "vue-property-decorator";

import UcfeForm from "@/modules/companies/components/UcfeForm.vue";
import FirmDgiForm from "@/modules/companies/components/FirmDgiForm.vue";
import { isArray, isEmpty } from "lodash";

@Component({ components: { FirmDgiForm, UcfeForm } })
export default class CompaniesUcfe extends Vue {
  @VModel({ type: Object, default: () => new Company() }) obCompany!: Company;

  get obFirm() {
    return this.obCompany.firm || {};
  }

  set obFirm(obData: Partial<FirmData>) {
    this.obCompany.set("firm", obData);
  }

  get obUcfe(): UcfeData {
    const obUcfe = this.obCompany.get("ucfe");
    return isArray(obUcfe) || isEmpty(obUcfe) ? {} : obUcfe;
  }

  set obUcfe(obData: Partial<UcfeData>) {
    this.obCompany.set("ucfe", obData);
  }
}
</script>
