<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel && drawer">
      <gw-form-observer @save="save" @cancel="cancel" :loading="isLoading">
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(slugify(item)) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <component :is="item" v-model="obModel" v-bind="getProps(item)" />
          </v-tab-item>
        </v-tabs-items>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Component, Mixins, PropSync, Provide } from "vue-property-decorator";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import FormTabSettings from "@/modules/companies/components/tabs/Settings.vue";
import FormTabImage from "@/modules/companies/components/tabs/Image.vue";
import FormTabBranchesForm from "@/modules/companies/components/BranchesForm.vue";
import FormTabDgi from "@/modules/companies/components/tabs/Dgi.vue";
import FormTabUcfe from "@/modules/companies/components/tabs/Ucfe.vue";
import FormTabCurrencyRates from "@/modules/companies/components/tabs/CurrenciesRates.vue";
import FormTabCurrencyRateRound from "@/modules/companies/components/tabs/CurrencyRateRound.vue";
import FormTabCurrencies from "@/modules/companies/components/tabs/Currencies.vue";
import FormTabCfeCodes from "@/modules/companies/components/CompanyCfeCodes.vue";
import FormTabInvoiceTypes from "@/modules/companies/components/tabs/InvoiceTypes.vue";
import FormTabModules from "@/modules/companies/components/CompanyModules.vue";
import FormTabTaxTypes from "@/modules/companies/components/tabs/TaxTypes.vue";
import { Branch, BranchCollection, Company } from "@planetadeleste/vue-mc-gw";
import { DebounceFunction } from "@/plugins/helpers";
import { ref } from "@/services/Utils";

@Component({
  components: {
    SheetDrawer,
    FormTabSettings,
    FormTabImage,
    FormTabBranchesForm,
    FormTabDgi,
    FormTabUcfe,
    FormTabCurrencies,
    FormTabCurrencyRates,
    FormTabCurrencyRateRound,
    FormTabCfeCodes,
    FormTabInvoiceTypes,
    FormTabModules,
    FormTabTaxTypes,
  },
})
export default class CompaniesForm extends Mixins(CompaniesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  @Provide() public isCustomerRef = ref<boolean>(false);

  tab = 0;
  tabItems: string[] = ["form-tab-settings", "form-tab-image", "form-tab-ucfe"];
  obBranch: Branch | null = null;
  fnDebounceBranch!: DebounceFunction;

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.before.sync`, (obModel: Company) => {
      obModel.unset("branches");
    });
  }

  onMounted() {
    this.$nextTick(() => {
      const arTabs: string[] = [
        "form-tab-branches-form",
        "form-tab-cfe-codes",
        "form-tab-tax-types",
        "form-tab-invoice-types",
        "form-tab-currencies",
        "form-tab-modules",
        "form-tab-currency-rates",
        "form-tab-currency-rate-round",
      ];
      if (
        this.$route.params.id &&
        this.$_.intersection(this.tabItems, arTabs).length !== arTabs.length
      ) {
        this.tabItems.splice(1, 0, arTabs[0]);
        this.tabItems.push(arTabs[1]);
        this.tabItems.push(arTabs[2]);
        this.tabItems.push(arTabs[3]);
        this.tabItems.push(arTabs[4]);
        this.tabItems.push(arTabs[5]);
      }

      this.loadBranch();
    });
  }

  created() {
    this.fnDebounceBranch = this.$_.debounce(this.saveBranch, 1000);
    this.onCreated();
  }

  async loadBranch() {
    if (!this.obModel.id) {
      return;
    }

    const obBranches = new BranchCollection();
    await obBranches.byDefault().byCompany(this.obModel.id).page(1).fetch();
    if (obBranches.length) {
      this.obBranch = obBranches.first() || null;
      if (this.obBranch) {
        this.obBranch.on("change", () => {
          this.fnDebounceBranch();
        });
      }
    }
  }

  async saveBranch() {
    if (!this.obBranch || !this.obBranch.isDirty()) {
      return;
    }

    this.obBranch.sync();
    await this.obBranch.store();
  }

  getProps(sComp: string): Record<string, any> {
    const obProps = {};

    switch (sComp) {
      case "form-tab-branches-form":
        this.$_.assign(obProps, { secondary: true });
        break;

      default:
        break;
    }

    return obProps;
  }
}
</script>
